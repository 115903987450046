<template>
  <b-row class="settings">
    <b-col>
      <PageHeader :title="$t('general.settingsPages.username.title')" />

      <b-row>
        <div class="settings__form">
          <div class="settings__input">
            <CInput
              type="text"
              name="username"
              theme="settings"
              class="settings__field"
              v-model="user.username"
              :label="$t('general.myProfile.input.username')"
              :rules="'required'"
            >
              <template #appendAfter>
                <transition name="fade" mode="out-in">
                  <CIcon
                    name="check"
                    class="settings__icon settings__icon--check"
                    v-if="user.username"
                  />
                  <CIcon
                    name="false"
                    class="settings__icon settings__icon--false"
                    v-else
                  />
                </transition>
              </template>

              <template #appendAddition>
                <span class="settings__counter">
                  <span class="settings__font settings__font--addition">
                    {{ appUrl }}/{{ user.username }}
                  </span>
                </span>
              </template>
            </CInput>
          </div>
        </div>

        <div class="settings__button-wrapper">
          <button
              type="button"
              @click.prevent="saveSettings"
              class="base-button settings__button"
          >
          <span class="base-button__font">
            {{ $t("general.privacyPage.button.update") }}
          </span>
          </button>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import PageHeader from "@/layout/PageHeader.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CInput from "@/features/ui/СInput.vue";
import User from "@/components/models/User";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
export default {
  name: 'PageSettingsUsername',
  mixins: [waitRequest, validationError],
  data() {
    return {
      user: null,
    };
  },

  computed: {

    appUrl() {
      return process.env.VUE_APP_APP_URL;
    }
  },

  mounted() {
    this.loadUser();
  },
  components: {
    CInput,
    CIcon,
    PageHeader,
  },
  methods: {
    loadUser() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$get(
            "/auth/me",
            (data) => {
              this.user = new User(data);
            },
            (errors) => {
              console.log(errors);
            },
            this.checkErrors
        );
      });
    },
    async saveSettings() {
      const isValid = await this.$validator.validateAll();

      if (!isValid || this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
            "/profile",
            {
              username: this.user.username,
              name: this.user.name,
              bio: this.user.bio,
              location: [1,2,3],
              website: this.user.website,
              sex: 2,
              orientation: 1,
            },

            (data) => {
              this.user = new User(data);
              this.$saveUser(data);
              this.$bvToast.toast(this.$t("general.settings-saved"), {
                autoHideDelay: 5000,
                title: this.$t("general.success"),
                solid: true,
                toaster: "b-toaster-bottom-left flow-toast",
              });
            },
            (errors) => {
              console.log("error", errors);
            },
            this.checkErrors
        );
      }).catch(this.checkErrors);
    },
  },
};
</script>


<style lang="scss" scoped>

.settings {
  &__form {
    margin-top: em(32);
    width: 100%;
    margin-right: em(16);
    margin-left: em(16);
  }


  &__button {
    margin-top: em(17);
    margin-right: em(15);
    margin-left: auto;
  }

  &__button-wrapper {
    display: flex;
    width: 100%;
    margin-top: em(19);
    border-top: 1px solid $app-gray-2;
  }

  &__input {
    margin-top: em(16);
    position: relative;
  }

  &__counter {
    position: absolute;
    top: 100%;
    right: em(0);
  }

  &__field {

  }

  &__font {
    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
